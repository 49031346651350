import './sprite';
import marquee from 'jquery.marquee';
import './vendors/jquery.validate.min';
import './vendors/jquery.maskedinput.min';

$(window).on('load', () => {

  const headerElem = document.querySelector('header');
  const headerControls = headerElem.querySelector('.header__controls');
  const headerMenu = headerElem.querySelector('.header-menu');
  const headerCloseMenu = headerElem.querySelector('.header__contacts-close');
  const catzwolfLogo = document.querySelector('.bg-section__logo');
  const letters = document.querySelectorAll('.bg-section__letter');
  const mainServices = document.querySelector('.main-services');
  const videoWrap = document.querySelector('.bg-section');
  const playButtonElem = document.querySelector('.js-video-play');
  const videoBgElem = videoWrap.getElementsByTagName('video')[0];
  const videoModal = document.getElementById('video-modal');
  const modalVideoElem = videoModal.getElementsByTagName('video')[0];
  const modalCloseElem = videoModal.querySelector('.modal__close');
  const infoBtn = document.querySelector('.main-info__btn');
  const infoBlock = document.querySelector('.main-info');
  const infoBlockText = document.querySelector('.main-info__text');
  const formBlock = document.querySelector('.form-section');
  const formWrap = formBlock.querySelector('.form-section__form-wrap');
  const formClose = formBlock.querySelector('.form-section__close');
  const feedbackForm = document.getElementById('feedback-form');

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  if (window.innerWidth < 768) {
    feedbackForm.style.height = `${window.innerHeight}px`;
  }

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (window.innerWidth < 768) {
      feedbackForm.style.height = `${window.innerHeight}px`;
    }
  });

  window.addEventListener("orientationchange", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (window.innerWidth < 768) {
      feedbackForm.style.height = `${window.innerHeight}px`;
    }
  });

  const initAnimation = () => {
    catzwolfLogo.classList.add('animate');
    setTimeout(() => {
      headerElem.classList.add('active');
      letters.forEach((item) => {
        item.classList.add('active');
      });
      mainServices.classList.add('active');
      playButtonElem.classList.add('active');
      infoBlock.classList.add('active');
      formBlock.classList.add('active');
    }, 1000);
    setTimeout(() => {
      videoWrap.classList.add('active');
    }, 1200);
  }

  setTimeout(() => {
    initAnimation();
  }, 500);

  $('.marquee-services').marquee({
    duration: 80000,
    gap: 0,
    delayBeforeStart: 0,
    direction: 'left',
    duplicated: true,
    startVisible: true
  });

  $('.m-animate.forwards').marquee({
    duration: 22000,
    gap: 0,
    delayBeforeStart: 0,
    direction: 'left',
    duplicated: true,
    startVisible: true
  });

  if (videoBgElem) {
    videoBgElem.addEventListener('canplaythrough', function () {
      videoBgElem.muted = true;
      videoBgElem.play();
    }, false);
  }

  playButtonElem.addEventListener('click', () => {
    if (modalVideoElem) {
      modalVideoElem.play();
    }
  });

  modalCloseElem.addEventListener('click', () => {
    if (modalVideoElem) {
      modalVideoElem.pause();
    }
  });

  const backdrop = document.createElement('div');

  document.addEventListener('click', function (event) {
    const target = event.target.closest('a[data-modal], button[data-modal]');

    if (target && target.dataset.modal === 'open') {
      closeAllModals();
      showModal(document.getElementById((target.hash || target.dataset.modalTarget).slice(1)));
    }

    if (target && target.dataset.modal === 'close' || event.target.matches('[aria-modal]')) {
      closeAllModals();
    }

    function showModal(targetModalNode) {

      document.body.classList.add('modal-open');

      targetModalNode.classList.add('modal--show');
      targetModalNode.style.display = 'block';
      targetModalNode.ariaModal = true;
      targetModalNode.ariaHidden = null;
      targetModalNode.setAttribute('role', 'dialog');

      backdrop.className = 'modal-backdrop';
      document.body.append(backdrop);
    }

    function closeAllModals() {
      document.body.classList.remove('modal-open');
      document.body.style.paddingRight = '';

      document.querySelectorAll('.modal').forEach(function (modal) {
        modal.classList.remove('modal--show');
        modal.style.display = 'none';
        modal.ariaModal = null;
        modal.ariaHidden = true;
        modal.removeAttribute('role');
      });

      backdrop.remove();
    }
  });

  $('[type="tel"]').mask("+7 (999) 999-99-99");

  jQuery.validator.addMethod("checkEmail", function(value, element) {

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(value);
  });

  jQuery.validator.addMethod("checkMask", function(value, element) {
    let pattern = new RegExp(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/);
    return pattern.test(value);
  });

  $('#feedback-form').validate({
    errorClass: "error",
    validClass: "success",
    errorElement: "span",
    focusInvalid: true,
    rules: {
      name: {
        required: true
      },
      phone: {
        //checkMask: true,
        required: true
      },
      email: {
        required: true,
        checkEmail: true,
        email: true
      },
      company: {
        required: true
      },
    },
    messages: {
      name: {
        required: "Укажите имя",
      },
      phone: {
        required: "Укажите номер телефона",
        //checkMask: "Укажите полный номер телефона"
      },
      email: {
        required: "Укажите email",
        checkEmail: "E-mail должен быть в формате name@domain.com",
        email: "E-mail должен быть в формате name@domain.com"
      },
      company: {
        required: "Укажите название компании",
      },
    },
    invalidHandler: function (event, validator) {
      let errors = validator.numberOfInvalids();
      if (errors) {
        console.log(errors);
      }
    },
    submitHandler: function() {
      $("em.error").hide();

      let curForm = document.getElementById('feedback-form');
      let url = $('#feedback-form').attr('data-api');
      let formData = new FormData(curForm);

      $.ajax({
        url: url,
        method: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        dataType: 'json',
        success: function (data) {
          $('#feedback-form').addClass('hidden');
          $('.feedback-form__success').removeClass('hidden');
        },
      });
    },
    highlight: function(element, errorClass, validClass) {
      $(element).addClass(errorClass).removeClass(validClass);
    },
    unhighlight: function(element, errorClass, validClass) {
      $(element).removeClass(errorClass).addClass(validClass);
    },
    errorContainer: "em",

    errorPlacement: function(error, element) {
      error.insertAfter(element);
    }
  });

  const buttonToForm = document.querySelector(".js-to-form");
  const animationToForm = document.getElementById("animation-to-form");
  const animationFormBack = document.getElementById('animation-form-back');
  const animationToFormTablet = document.getElementById("animation-to-form-tablet");
  const animationFormBackTablet = document.getElementById('animation-form-back-tablet');
  const contactsBtn = headerElem.querySelector('.js-contacts');
  const animationToContacts = document.getElementById("animation-to-contacts");
  const animationBackContacts = document.getElementById("animation-back-contacts");
  const animationToContactsMobile = document.getElementById("animation-to-contacts-mobile");
  const animationBackContactsMobile = document.getElementById("animation-back-contacts-mobile");
  const animationToInfo = document.getElementById("animation-to-info");
  const animationBackInfo = document.getElementById("animation-back-info");
  const animationToInfoMobile = document.getElementById("animation-to-info-mobile");
  const animationInfoMobileBack = document.getElementById("animation-to-info-mobile-back");

  const resetFrame = () => {
    if (contactsBtn.classList.contains('active')) {
      animationBackContacts.beginElement();
      animationBackContactsMobile.beginElement();
      contactsBtn.classList.remove('active');
      headerControls.classList.remove('active');
      headerMenu.classList.remove('active');
      headerElem.classList.remove('shown');
    }

    if (buttonToForm.classList.contains('active')) {
      buttonToForm.classList.remove('active');
      formWrap.classList.remove('active');
      formClose.classList.remove('active');
      setTimeout(() => {
        animationFormBack.beginElement();
        animationFormBackTablet.beginElement();
        formBlock.classList.remove('shown');
      }, 200);
    }

    if (infoBtn.classList.contains('active')) {
      animationBackInfo.beginElement();
      animationInfoMobileBack.beginElement();
      infoBlock.classList.remove('shown');
      infoBlockText.classList.remove('active');
      infoBtn.classList.remove('active');
    }
  }

  infoBtn.addEventListener('click', function() {
    if (infoBtn.classList.contains('active')) {
     resetFrame();
    } else {
      resetFrame();
      animationToInfo.beginElement();
      animationToInfoMobile.beginElement();
      infoBlock.classList.add('shown');
      infoBtn.classList.add('active');
      infoBlockText.classList.add('active');
    }
  }, false);

  buttonToForm.addEventListener('click', function() {
    if (buttonToForm.classList.contains('active')) {
      $('#feedback-form').submit();
    } else {
      resetFrame();
      animationToForm.beginElement();
      animationToFormTablet.beginElement();
      buttonToForm.classList.add('active');
      formWrap.classList.add('active');
      formBlock.classList.add('shown');
      formClose.classList.add('active');
    }
  }, false);

  contactsBtn.addEventListener('click', function() {
    if (contactsBtn.classList.contains('active')) {
      resetFrame();
    } else {
      resetFrame();
      animationToContacts.beginElement();
      animationToContactsMobile.beginElement();
      contactsBtn.classList.add('active');
      headerControls.classList.add('active');
      headerElem.classList.add('shown');
      setTimeout(() => {
        headerMenu.classList.add('active');
      }, 200);
    }
  }, false);

  headerCloseMenu.addEventListener('click', function() {
    resetFrame();
  }, false);

  formClose.addEventListener('click', function() {
    resetFrame();
  }, false);

  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('main-screen')) {
      resetFrame();
    }
  }, false);
});
